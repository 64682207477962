import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("../layouts/BaseLayout"),
    meta: {
      title: "首页",
      requireAuth: false,
      key: "index",
    },
    children: [
      {
        path: "/home",
        component: () => import("../views/index/Home"),
        meta: {
          title: "首页",
          requireAuth: true,
        },
      },
      {
        path: "/news",
        redirect: "/news/list",
        component: () => import("../views/news/News"),
        meta: {
          title: "新闻",
          requireAuth: false,
          key: "news",
        },
        children: [
          {
            path: "/news/list",
            component: () => import("../views/news/NewsList"),
            meta: {
              title: "新闻列表",
              requireAuth: true,
            },
          },
          {
            path: "/news/item",
            component: () => import("../views/news/NewsItem"),
            meta: {
              title: "新闻详情",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/case",
        redirect: "/case/list",
        component: () => import("../views/case/Case"),
        meta: {
          title: "案例",
          requireAuth: false,
          key: "case",
        },
        children: [
          {
            path: "/case/list",
            component: () => import("../views/case/CaseList"),
            meta: {
              title: "案例介绍",
              requireAuth: true,
            },
          },
          {
            path: "/case/item",
            component: () => import("../views/case/CaseItem"),
            meta: {
              title: "案例详情",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/product",
        redirect: "/product/list",
        component: () => import("../views/product/Product"),
        meta: {
          title: "产品",
          requireAuth: false,
          key: "product",
        },
        children: [
          {
            path: "/product/list",
            component: () => import("../views/product/ProductList"),
            meta: {
              title: "产品列表",
              requireAuth: true,
            },
          },
          {
            path: "/product/item",
            component: () => import("../views/product/ProductItem"),
            meta: {
              title: "产品详情",
              requireAuth: true,
            },
          },
        ],
      },
      {
        path: "/info",
        component: () => import("../views/pages/Info"),
        meta: {
          title: "走进绿能",
          requireAuth: true,
        },
      },
      {
        path: "/about",
        component: () => import("../views/pages/About"),
        meta: {
          title: "关于",
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/404",
    meta: {
      title: "404",
      requireAuth: false,
      key: "404",
    },
    component: () => import("../views/error/404"),
  },
  {
    path: "/40301",
    meta: {
      title: "网站被禁用",
      requireAuth: false,
      key: "40301",
    },
    component: () => import("../views/error/40301"),
  },
  {
    path: "/40302",
    meta: {
      title: "网站到期",
      requireAuth: false,
      key: "40302",
    },
    component: () => import("../views/error/40302"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
