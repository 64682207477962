import axios from "axios";
import qs from "qs";
import router from "../router";

// 创建axios 实例

const service = axios.create({
  baseURL: "https://wapi.pigwan.net/",
  timeout: 10000, // 请求超时时间
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

// request 拦截器
service.interceptors.request.use(
  (config) => {
    // config.headers.Authorization = state.authorization;
    // 这里可以自定义一些config 配置
    config.data = qs.stringify(config.data); // 转为formdata数据格式
    //判断get
    if (config.method === "get" && config.data) {
      config.url = config.url + "?" + config.data;
    }
    return config;
  },
  (error) => {
    //  这里处理一些请求出错的情况
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    //如果存在authorization就更新
    // if (response.headers.authorization !== undefined) {
    //     store.commit("changeAuthorization", response.headers.authorization);
    // }
    //如果返回code是40xxx,跳转登陆页面
    if (res.code > 40000 && res.code < 50000) {
      if (res.code === 40301) {
        router.push({ path: "/40301" });
      } else if (res.code === 40302) {
        router.push({ path: "/40302" });
      } else {
        router.push({ path: "/404" });
      }
    }
    // 这里处理一些response 正常放回时的逻辑
    return res;
  },
  (error) => {
    // 这里处理一些response 出错时的逻辑
    return Promise.reject(error);
  }
);

export default service;
