import request from "../utils/request";

export default {
  getWebsiteSet(domain) {
    return request({
      url: "/set/website/"+domain,
      method: "get",
    });
  },
};
