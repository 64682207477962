import store from "../store";

export default {
    //file转base64
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function(e) {
            callback(e.target.result);
        };
    },
    //判断是否为手机号
    isPhoneNumber(tel) {
        return /^0?1[3|4|5|6|7|8][0-9]\d{8}$/.test(tel);
    },
    //判断是否为字母数字下划线
    isValid(str) {
        return /^\w+$/.test(str);
    },
    //判断是否为字母
    isLetter(str) {
        for (let i in str) {
            let asc = str.charCodeAt(i);
            if ((asc >= 65 && asc <= 90 || asc >= 97 && asc <= 122)) {
                return true;
            }
        }
        return false;
    },
    //判断是否为json
    isJsonString(str) {
        try {
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch (e) {
            return false;
        }
        return false;
    },
    //logout
    logout(){
        store.commit("login", "");
        store.commit("changeAuthorization", "");
    },


    formatDate(datetime){

    var date = new Date(datetime*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var year = date.getFullYear(),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        sdate = ("0" + date.getDate()).slice(-2),
        hour = ("0" + date.getHours()).slice(-2),
        minute = ("0" + date.getMinutes()).slice(-2),
        second = ("0" + date.getSeconds()).slice(-2);
    // 拼接
        // 返回
    return year + "-" + month + "-" + sdate + " " + hour + ":" + minute + ":" + second;
}

};
