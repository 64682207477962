<template>
  <router-view />
</template>


<script>
import set from "./api/set";
import store from "./store";

export default {
  name: "App",
  components: {},
  mounted() {
    this.getWebsiteSet();
  },
  methods: {
    //获取网站配置信息
    getWebsiteSet() {
      let domain = "www.hnxjln.com";
      set.getWebsiteSet(domain)
        .then((res) => {
          if (res.code === 0) {
            store.commit("getWebsiteSet", res.data);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
#app {
  min-width: 1366px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
}
</style>
